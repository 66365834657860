/***********IMG GRID***************/
.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}
.centered {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* Create four equal columns that sits next to each other */
.column {
  flex: 25%;
  max-width: 33.3%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  filter: brightness(1);
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s linear;
}
.column img:hover {
  filter: grayscale(0);
}
@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
  .column img {
    filter: grayscale(0) brightness(1);
  }
}

/***********Modal**********/
.modal {
  border-radius: 25px;
  box-shadow: 0 0 5px 1px #fc7b20;
}
.land-card {
  filter: brightness(0.3);
  transition: 0.3s linear;
}
.land-card-footer {
  background-color: rgba(0, 0, 0, 0.7);
}

.land-pad {
  padding-top: 50px;
}

div.modal-body {
  border-radius: 25px;
}
.my-modal {
  width: 100%; /* Occupy the 90% of the screen width */
  max-width: 400px;
}
/********************FOOTER*******************/
/* Main Footer */

footer ul {
  padding-left: 0;
  list-style: none;
}

.footer-copyright {
  background: black;
  padding: 5px 0;
}

.widget-title {
  margin-bottom: 20px;
  font-family: "Ultra", serif;
  text-shadow: 4px 4px 4px #aaa;
  font-size: 30px;
}
.widget-title span {
  background: #839fad none repeat scroll 0 0;
  display: block;
  height: 1px;
  margin-top: 25px;
  position: relative;
  width: 20%;
}
.widget-title span::after {
  background: inherit;
  content: "";
  height: inherit;
  position: absolute;
  top: -4px;
  width: 50%;
}
.widget-title.text-center span,
.widget-title.text-center span::after {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
.widget .badge {
  float: right;
  background: goldenrod;
}

.typo-light div {
  color: #fdc92a;
}

.foot-links {
  color: rgba(252, 123, 32, 255);
  font-size: 20px;
  font-weight: bolder;
  font-family: "Playfair Display", serif;
}
.links {
  color: rgba(252, 123, 32, 255);
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
  font-family: "Playfair Display", serif;
}
.foot-links:hover {
  color: white;
}
.links:hover {
  color: white;
}

.line {
  width: 112px;
  height: 47px;
  color: #fdc92a;
  border-bottom: 2px solid #fdc92a;
  position: absolute;
}

.menu-icon {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-100%, 60%);
  font-size: 1.8rem;
  cursor: pointer;
}
/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .App {
    max-width: 2000px;
  }
}

/* Facebook */
.fa-facebook {
  font-size: 20px;
  color: #3b5998;
  padding: 10px;
}
.fa-phone-alt {
  font-size: 20px;
  color: rgba(252, 123, 32, 255);
  padding: 10px;
}
.fa-envelope {
  font-size: 20px;
  color: rgba(252, 123, 32, 255);
  padding: 10px;
}

h2.minor-txt {
  font-size: 10px;
  font-weight: bolder;
  color: #fc7b20;
  text-align: left;
  justify-content: left;
}

.title-price {
  text-align: center;
  font-family: "Ultra", serif;
  font-size: 20px;
  text-shadow: 4px 4px 4px #aaa;
}
.copyright {
  color: #fc7b20;
  font-family: "Playfair Display", serif;
}

.form-text {
  font-size: 25px;
  font-family: "Playfair Display", serif;
}

.titles {
  text-align: center;
  font-family: "Ultra", serif;
  font-size: 20px;
  text-shadow: 4px 4px 4px #aaa;
}
.morepad {
  text-align: center;
  font-family: "Ultra", serif;
  font-size: 15px;
  text-shadow: 4px 4px 4px #aaa;
  padding: 20px;
}
.modal-titles {
  text-align: center;
  font-family: "Ultra", serif;
  font-size: 20px;
  text-shadow: 4px 4px 4px #aaa;
}
.titles::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #fdc92a;
  bottom: 0;
  left: calc(50% - 25px);
}
.title-price::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #fdc92a;
  bottom: 0;
  left: calc(50% - 25px);
}
.hours {
  list-style: none;
  text-align: left;
  display: inline-block;
  font-weight: bolder;
  font-size: 15px;
  font-weight: bolder;
  font-family: "Playfair Display", serif;
  color: #fc7b20;
}
.money {
  color: green;
  font-weight: bold;
  font-size: 20px;
  font-family: "Playfair Display", serif;
  text-align: left;
}
.card-title {
  font-family: "Ultra", serif;
  text-shadow: 4px 4px 4px #aaa;
  font-size: 20px;
  text-align: center;
}
.card-titles {
  text-align: center;
  font-family: "Ultra", serif;
  font-size: 15px;
  text-shadow: 4px 4px 4px #aaa;
}
/********************IMAGES******************/
.land-img {
  height: 450px;
  width: 100%;
  filter: brightness(0.3);
  transition: 0.3s linear;
}

#footer .main-footer {
  background-image: url("./pics/footxl-p.jpg");
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
}

/********ICONS***************/
.fa-dumbbell {
  justify-content: center;
  color: goldenrod;
  size: 50px;
}
.class-img {
  height: 300px;
  padding: 10px;
}
/** IMAGE EFFECTS HOME ***************/

/* Flashing */
.hover13 figure:hover img {
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}
@-webkit-keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.serv-img-b {
  height: 300px;
}
.card {
  height: 550px;
  width: 250px;
  background-image: url("./pics/bright.jpg");
}
/*****************************ICONS********************/
.bi-check {
  height: 35px;
  width: 35px;
}

/************************RESPONSIVE********************************/
/************************SECTIONS*************/
.about-sec {
  background-color: whitesmoke;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.serv-sec {
  margin-top: -400px;
  padding: 35px 10px 30px 10px;
}

.class-sec {
  padding: 35px 35px 30px 40px;
}
.price-sec {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*******PADDING**********/

.row-pad {
  padding-bottom: 250px;
}
.adv {
  padding-top: 50px;
}
.display-pad {
  padding-top: 50px;
  padding-bottom: 50px;
}
/*****************TXT***************************/

.headers {
  text-align: center;
  font-family: "Ultra", serif;
  font-size: 20px;
  text-shadow: 4px 4px 4px #aaa;
  padding: 25px;
}
.titles {
  text-align: center;
  font-family: "Ultra", serif;
  font-size: 25px;
  text-shadow: 4px 4px 4px #aaa;
}
.card-titles {
  text-align: center;
  font-family: "Ultra", serif;
  font-size: 20px;
  text-shadow: 4px 4px 4px #aaa;
}

h1.webtxt {
  color: rgba(252, 123, 32, 255);
  font-family: "Ultra", serif;
  font-size: 18px;
}
p.webtxt {
  font-size: 15px;
  font-family: "Playfair Display", serif;
  text-align: center;
}
h2.webtxt {
  font-size: 16px;
  font-weight: bold;
  font-family: "Playfair Display", serif;
  text-align: center;
}
.webtxt2 {
  font-size: 50px;
  font-weight: bolder;
  text-align: center;
  font-family: "Playfair Display", serif;
  color: #fdc92a;
}
p.land-webtxt {
  font-size: 25px;
  font-weight: bolder;
  font-family: "Tangerine", cursive;
  color: #fdc92a;
  text-align: center;
}

/************CARDS********************************/
.card-col {
  padding: 25px;
}
.pad {
  padding: 20px;
}
.small-pad {
  padding-top: 30px;
}
.card {
  height: 450px;
  width: 100%;
}
.adv-cards {
  height: 400px;
}
.row-pad {
  padding-bottom: 100px;
}
.infoz {
  padding-bottom: 50px;
}
/****************Buttons**************/
button.btn.btn-info {
  color: black;
  background-color: transparent;
  border: 2px solid #fdc92a;
  box-shadow: 2px 2px 2px 2px goldenrod;

  text-align: center;
  justify-content: center;
  font-weight: bolder;
  font-family: "Playfair Display", serif;
  padding: 5px;
  font-size: 10px;
}

button.btn.btn-outline-primary {
  color: #fdc92a;
  background-color: transparent;
  border: 2px solid #fdc92a;
  box-shadow: 2px 2px 2px 2px goldenrod;
  font-size: 15px;
  text-align: center;
  justify-content: center;
  font-weight: bolder;
  font-family: "Playfair Display", serif;
  padding: 10px;
}

button.btn.btn-info:hover {
  background-color: #fdc92a;
  color: black;
  border-color: white;
  box-shadow: 2px 2px 2px 2px #fdc92a;
}
button.btn.btn-outline-primary:hover {
  color: black;
  border-color: white;
  box-shadow: 2px 2px 2px 2px white;
}
.btnTxt {
  font-size: 3px;
}
.serv-row {
  padding: 60px;
}
.bi-envelope {
  height: 80px;
  width: 80px;
}
.bi-telephone-outbound {
  height: 80px;
  width: 80px;
}
.location {
  height: 500px;
  padding: 20px;
}
.wrapper {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.form-wrapper {
  height: 700px;
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  background-color: #ffffff;
  box-shadow: 0 0 5px 1px #fc7b20;
  width: 300px;
}

form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

label {
  font-size: 0.8em;
  margin-bottom: 0.25em;
  font-weight: bold;
}

input {
  padding: 12px 20px;
  border: 1px solid #fc7b20;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}
.message textarea {
  border: 1px solid #fc7b20;
  border-radius: 4px;
}
.message textarea:hover {
  border: 1px solid #fc7b20;
}
textarea:focus {
  border: 1px solid #fc7b20;
}
input::placeholder {
  font-size: 1.2em;
  font-weight: lighter;
  color: #999;
}
input:hover {
  box-shadow: 0 0 5px 1px #fc7b20;
}
input.error {
  border: 1px solid red;
}
input:focus {
  border: 1px solid #111;
  transition: 0.3s gold;
}
.errorMessage {
  color: red;
  font-size: 0.75em;
  display: relative;
}

.firstName {
  margin-right: 1%;
}

.lastName {
  margin-left: 1%;
}

.firstName,
.lastName,
.email,
.phone,
.message {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.firstName,
.lastName {
  width: 49%;
}
.email,
.message,
.phone {
  width: 100%;
}

*:focus {
  outline: none !important;
}
.submit-but {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submit-but button {
  background-color: #fdc92a;
  color: black;
  border: 2px solid gold;
  width: 100%;
  margin-top: 1em;
  padding: 8px 0px;
  font-size: 1em;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 0.25em;
  border-radius: 12px;
}

.submit-but button:hover {
  background-color: gold;
  color: black;
  border-color: white;
}
/************ NAVIGATION***************************/
.navbar-logo {
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
}
.nav-menu {
  display: grid;
  grid-template-columns: repeat(8, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.navbar-toggler {
  background: #fc7b20;
}
.navbar-toggler-icon {
  color: #fc7b20;
}
.navbar-collapse {
  background-color: rgba(0, 0, 0, 0.5);
}
a.nav-link {
  text-align: center;
  padding: 10px;
  width: 100%;
  display: table;
  color: rgba(252, 123, 32, 255);
  font-weight: bolder;
  font-family: "Playfair Display", serif;
}

a.nav-link:hover {
  color: white;
  text-shadow: 6px 6px 6px #aaa;
}
#navbar {
  font-weight: bolder;
  font-family: "Playfair Display", serif;
  height: 80px;
  background-color: black;
  display: flex;
}
#logo {
  height: 100px;
  width: 200px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -30px;
}

/***********************PADDING******************/
.top-pad {
  padding-top: 25px;
}
.center-pad {
  padding-top: 25px;
  padding-bottom: 25px;
}
/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .land_imgs {
    height: 400px;
  }

  .webtxt {
    font-size: 30px;
    text-align: center;
  }

  .titles {
    text-align: center;
    font-family: "Ultra", serif;
    font-size: 25px;
    text-shadow: 4px 4px 4px #aaa;
  }

  .serv-img-b {
    height: 300px;
  }
  .adv-cards {
    height: 400px;
  }
  footer .main-footer {
    padding: 20px 0;
    background-image: url("./pics/footxl-p.jpg");

    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  /***************************** Landscape */
  @media only screen and (orientation: landscape) {
    .adv-cards {
      min-width: 300px;
    }
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .webtxt {
    font-size: 25px;
    padding: 10px;
    text-align: center;
  }
  .adv-cards {
    height: 300px;
  }

  .webtxt2 {
    font-size: 50px;
    font-weight: bolder;
    text-align: center;
    font-family: "Playfair Display", serif;
    color: #fdc92a;
  }

  .card-titles {
    text-align: center;
    font-family: "Ultra", serif;
    font-size: 10px;
    text-shadow: 4px 4px 4px #aaa;
  }

  .infoz {
    margin-top: -150px;
    padding-bottom: 350px;
  }
  .comb {
    padding-top: 50px;
  }
  .card-title {
    font-size: 20px;
  }

  .serv-row {
    padding: 50px;
  }
  .location {
    width: 100%;
    height: 600px;
  }
  .serv-img-b {
    height: 300px;
  }
  .form-wrapper {
    height: 700px;
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    background-color: #ffffff;
    box-shadow: 0 0 5px 1px #fc7b20;
    width: 600px;
  }
  .class-sec {
    padding: 100px 0px 100px 0px;
  }
  .centered {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/*Large devices (desktops,ipadPro, 992px and up)*/
@media (min-width: 992px) {
  .adv-cards {
    height: 400px;
  }
  footer .main-footer {
    padding: 20px 0;
    background-image: url("./pics/footxl-p.jpg");

    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .card-titles {
    text-align: center;
    font-family: "Ultra", serif;
    font-size: 15px;
    text-shadow: 4px 4px 4px #aaa;
  }

  .titles {
    text-align: center;
    font-family: "Ultra", serif;
    font-size: 40px;
    text-shadow: 4px 4px 4px #aaa;
  }

  .comb {
    padding: 50px;
  }
  .card {
    height: 400px;
    width: 100%;
  }
  .location {
    height: 600px;
  }
  .form-wrapper {
    height: 600px;
  }
  #logo {
    height: 100px;
    width: 250px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  .navbar-logo {
    justify-self: start;
    margin-left: 120px;
    cursor: pointer;
  }
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(8, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 100%;
  }
  h1.webtxt {
    color: rgba(252, 123, 32, 255);
    font-size: 30px;
  }
  p.webtxt {
    font-size: 20px;
    font-family: "Playfair Display", serif;
    text-align: center;
  }
  .class-sec {
    padding: 100px 0px 100px 0px;
  }
  .land-img {
    height: 550px;
    width: 100vw;
    filter: brightness(0.3);
    transition: 0.3s linear;
  }
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .serv-img-b {
    width: 100%;
    height: 600px;
  }

  .headers {
    text-align: center;
    font-family: "Ultra", serif;
    font-size: 40px;
    text-shadow: 4px 4px 4px #aaa;
  }

  .webtxt2 {
    font-size: 30px;
    font-weight: bolder;
    text-align: center;
    font-family: "Playfair Display", serif;
    color: #fdc92a;
  }

  h2.minor-txt {
    font-size: 15px;
    font-weight: bolder;
    color: #fc7b20;
    text-align: left;
  }
  footer .main-footer {
    padding: 20px 0;
    background-image: url("./pics/footxl-p.jpg");
    background-repeat: repeat;
    background-size: cover;
    background-position: center;
    height: auto;
    border: 2px solid white;
  }

  /*****************TXT***************************/
  #text {
    padding-top: 100px;
  }
  h1.webtxt {
    color: rgba(252, 123, 32, 255);
  }

  /********************IMAGES******************/

  /********ICONS***************/
  .fa-dumbbell {
    justify-content: center;
    color: goldenrod;
    size: 50px;
  }

  /************************RESPONSIVE********************************/
  /************************SECTIONS*************/
  .about-sec {
    background-color: whitesmoke;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .price-sec {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("./pics/equip.jpg");
  }
  /*******PADDING**********/

  .row-pad {
    padding-bottom: 250px;
  }
  /*****************TXT***************************/

  .titles {
    text-align: center;
    font-family: "Ultra", serif;
    font-size: 40px;
    text-shadow: 4px 4px 4px #aaa;
  }
  .titles::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #fdc92a;
    bottom: 0;
    left: calc(50% - 25px);
  }
  .card-titles {
    font-size: 20px;
  }

  #text {
    padding-top: 0px;
    margin-top: -100px;
  }
  h1.webtxt {
    color: rgba(252, 123, 32, 255);
    font-size: 30px;
  }
  .webtxt {
    font-size: 25px;
    font-family: "Ultra", serif;
  }
  p.webtxt {
    font-size: 20px;
    font-family: "Playfair Display", serif;
    text-align: center;
  }
  .webtxt2 {
    font-size: 50px;
    font-weight: bolder;
    text-align: center;
    font-family: "Playfair Display", serif;
    color: #5f5c51;
  }
  .title-price {
    text-align: center;
    font-family: "Ultra", serif;
    font-size: 40px;
    text-shadow: 4px 4px 4px #aaa;
    padding: 10px;
  }
  .money {
    font-size: 25px;
  }

  /************CARDS********************************/
  .card-col {
    padding: 50px;
  }
  .card {
    height: 550px;
    width: 100%;
  }
  .adv-cards {
    height: 400px;
  }

  .infoz {
    margin-top: -200px;
    padding-bottom: 150px;
  }
  /****************Buttons**************/
  button.btn.btn-info {
    font-size: 20px;
  }
  .extra-padding {
    padding-top: 250px;
  }
  .comb {
    padding-top: 150px;
  }
  .display-pad {
    padding-top: 50px;
  }
  .comb {
    padding-top: 150px;
  }
  .bi-envelope {
    height: 80px;
    width: 80px;
  }
  .bi-telephone-outbound {
    height: 80px;
    width: 80px;
  }
  .location {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  .class-img {
    height: 500px;
    width: 100%;
  }
  .center-pad {
    padding-top: 250px;
  }
  .text-bord {
    border-radius: 25px;
    box-shadow: 0 0 5px 1px #fc7b20;
    height: 600px;
  }
  .adv {
    padding-top: 100px;
  }
  #logo {
    height: 100px;
    width: 300px;
    margin-top: 0px;
    margin-left: 0px;
  }
  #navbar {
    height: 90px;
    background-color: transparent;
  }
  #navbar:hover {
    box-shadow: 0 0 10px 2px #fc7b20;
  }
  .my-modal {
    width: 600px; /* Occupy the 90% of the screen width */
    max-width: 600px;
  }
  .card-title {
    font-family: "Ultra", serif;
    text-shadow: 4px 4px 4px #aaa;
    font-size: 35px;
    text-align: center;
    padding: 10px;
  }
  .sub-header {
    font-family: "Ultra", serif;
    text-shadow: 4px 4px 4px #aaa;
    font-size: 25px;
    text-align: center;
  }
  .card-titles {
    text-align: center;
    font-family: "Ultra", serif;
    font-size: 20px;
    text-shadow: 4px 4px 4px #aaa;
  }
  .serv-row {
    padding: 25px;
  }
  .form-text {
    font-size: 25px;
  }
  p.land-webtxt {
    font-size: 35px;
    font-weight: bolder;
    font-family: "Tangerine", cursive;
    color: #fdc92a;
  }
  .top-pad {
    padding-top: 100px;
  }
  .center-pad {
    padding-top: 150px;
  }
  .form-wrapper {
    height: 740px;
  }

  #footer .main-footer {
    background-image: url("./pics/footxl.jpg");
    background-repeat: repeat;
    background-size: cover;
    background-position: center;
  }
  .morepad {
    padding: 200px;
    font-size: 40px;
  }
  ul.webtxt {
    font-size: 40px;
    font-weight: bolder;
    font-family: "Tangerine", cursive;
    text-align: center;
  }
  .line {
    width: 112px;
    height: 47px;
    color: #fdc92a;
    border-bottom: 2px solid #fdc92a;
    position: absolute;
  }

  .class-sec {
    padding: 100px 0px 100px 0px;
  }
  .serv-sec {
    margin-top: -400px;
    padding: 70px 70px 60px 80px;
  }
  .landing {
    padding-top: 200px;
    width: 100%;
    height: 100%;
  }
  .land-pad {
    padding-top: 0px;
  }
  /***************FORMS************/
  .form-wrapper {
    height: 700px;
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 0 5px 1px #fc7b20;
    width: 100%;
  }
  .land-img {
    height: 100vh;
    width: 100vw;
    filter: brightness(0.3);
    transition: 0.3s linear;
  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (orientation: landscape) {
  .centered {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
