@media screen and (max-width: 993px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -500px;
    transition: all 0.5s;
  }
  @media (orientation: landscape) {
    .nav-menu {
      top: 20px;
    }
  }
  .nav-menu.active {
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    opacity: 1;
    transition: all 0.5s;
    z-index: 1;
    height: 100%;
    position: fixed;
    width: 100%;
    font-weight: bolder;
    padding-bottom: 120px;
  }
  @media (orientation: landscape) {
    .nav-menu.active {
      padding-bottom: 12px;
    }
  }

  a.nav-link {
    text-align: center;
    padding: 10px;
    width: 100%;
    display: table;
    color: rgba(252, 123, 32, 255);
  }

  .nav-links:hover {
    background-color: red;
    border-radius: 0;
  }
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .fa-times {
    color: rgba(252, 123, 32, 255);
    font-size: 2rem;
  }
  .fa-bars {
    color: rgba(252, 123, 32, 255);
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
  }
}
